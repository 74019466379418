import * as React from "react";
import Footerlogin  from "../../components/Login/Footerlogin";
import Contenidologin from "../../components/Login/Contenidologin";
const Login = ({ setAuthState, setUsuario }) => {
  return (
    <>
      <div className="flex flex-col h-screen"> 
      <Contenidologin/>
        <Footerlogin/>
      </div>
    </>
  );
};
export default Login;
