import { React, useState } from "react";
import Sidebar from "../components/Sidebar";
import { Outlet } from "react-router-dom";
function LayoutSplash({ autusuario,permisos }) {
  return (
    <>    
    <div className="flex flex-row h-screen"> 
        <Sidebar autusuario={autusuario} permisos={permisos}/>
        <div className="w-full ">
          <Outlet />
        </div>
      </div>
    </>
  );
}
export default LayoutSplash;
