import { useState } from "react"

import { MdEventNote } from "react-icons/md";
import { IoMdHome } from "react-icons/io";
import { IoSettingsOutline, IoClose, IoMenu } from "react-icons/io5";
import { FaWhatsapp, FaChevronDown, FaChevronLeft, FaChevronRight, FaRoute, FaDoorOpen } from "react-icons/fa";
import { Link } from "react-router-dom";
import { FaChartPie , FaFileInvoice } from "react-icons/fa";
import { GiMoneyStack } from "react-icons/gi";
import { LuConstruction } from "react-icons/lu";
const icons = {
    menu: IoMenu,
    carretro: FaRoute,
    chevrondown: FaChevronDown,
    chevronleft: FaChevronLeft,
    chevronright: FaChevronRight,
    config: IoSettingsOutline,
    siniestralidad: MdEventNote,
    home: IoMdHome,
    whatsapp: FaWhatsapp,
    close: IoClose,
    salir: FaDoorOpen,
    estadísticas:FaChartPie ,
    factura:FaFileInvoice ,
    obra:LuConstruction,
    cotizacion:GiMoneyStack
};

   
const DynamicIcon = ({ iconName, color,size }) => {
    const IconComponent = icons[iconName];
    return IconComponent ? (
        <IconComponent className={`text-[${size}] mt-1`} color={color} />
    ) : null; // Puedes personalizar tamaño y color
};

export default function SidebarItem({ item ,expanded }) {
    const [open, setOpen] = useState(false)
    const handleMenuclick=()=>{
       setOpen(!open)
       console.log(item)
     
    }
  
    if (item.menus.length) {
        return (
            <div className={`w-[19vw] flex border-1 flex-col mb-1 gap-1 justify-between 
              h-[5vh]  text-white
               ${expanded ? "bg-menusidebar-100 hover:bg-menusidebar-200":""} rounded-md`}>                
                <div className="flex flex-row gap-4 justify-between items-center h-[5vh] ">
                <Link to={item.ruta} target={item.win} 
                        
                        className={` ${expanded ? "":"flex flex-col items-center  mb-2"}`}>
                            <DynamicIcon iconName={item.icono} size="1.5em"/> 
                </Link> 
                    <span   className={`${expanded && open? "hidden" : ""} w-full pl-3`}>{item.titulo}</span>
                        <Link to={item.ruta} target={item.win} 
                        onClick={() => setOpen(!open)} 
                        className={` ${open && "rotate-90"} transition-all`}>
                            <DynamicIcon iconName="chevronright" size="1em" /></Link>
                </div>           
            <div className={`absolute mt-7 -ml-2 p-4 border-[1px] border-gray-100 shadow-lg 
                shadow-gray-500  h-screen  gap-2 border-solid border-1 border-gray-500
                bg-gray-600  rounded-md  ${open ? "" : "hidden"}`}>
                 {item.menus.map((child, index) => <SidebarItem key={index} item={child} />) }
                </div>                
            </div >
        )
    }
    return (
        <div className={`${expanded ? "bg-red h-auto gap-2  w-[19vw] mb-1 bg-menusidebar-100 hover:bg-menusidebar-200  text-whit flex justify-star items-center rounded-md":
            "bg-red h-auto gap-2  w-[19vw] mb-1   text-whit flex justify-star items-center rounded-md"
        }
               `}>
        <Link
         className="flex w-[20vw] h-[5vh] items-center text-[.9rem]"
         to={item.ruta} 
         target={item.win} > <DynamicIcon iconName={item.icono}  size="1.5em"/>
            <span className={` ${expanded ? "text-[14px]  mt-1 px-10":"px-10"}`}>{item.titulo}</span></Link>
        </div>
    )

}