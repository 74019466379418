import * as React from "react";
import { useState, useEffect, useRef } from "react";
import Login from "./auth/Login";

import { auth } from "./data/fire";
import { onAuthStateChanged } from "firebase/auth";
import { HashRouter, Route, Routes } from "react-router-dom";
// Auth
import LayoutAuth from "./layouts/LayoutAuth";
import LayoutAd1 from "./layouts/LayoutAd1";
import LayoutSplash from "./layouts/LayoutSplash";
import Usuarioform from "./paginas/Admin/Adm/Usuarios";




import Error404 from "./Error404";
// siniestralidad
import Siniestralidad from "./paginas/Admin/Sin/Siniestralidad";
// estadisticas
import Estadisticas from "./paginas/Admin/Est/Estadisticas";

// Configuracion
import Settings from "./paginas/Cnf/Settings";
// Configuracion
import Usuarios from "./paginas/Admin/Adm/Usuarios";


import Configuracion from "./data/configuracion";
import apibig from "./components/Apibig" // Importa la configuración de Axios
function App() {
  const [usuario, setUsuario] = React.useState(null);
  const [authState, setAuthState] = React.useState(null);
  const [autusuario, setAutUsuario] = React.useState([]);
  const [permisos, setPermisos] = React.useState(null);
  /*
    const data = {
      correo: "carlos.arreola@fyfasesores.mx",
      permisos: [
        {
          id: 1,
          titulo: "Siniestralidad",
          clave: "siniestralidad",
          icono: "siniestralidad",
          ruta: "",
          win: "",
          menus: [
            {
              id: 1,
              titulo: "Siniestralidad 2023",
              clave: "siniestralidad2023",
              icono: "siniestralidad",
              ruta: "",
              win: "",
              menus: [
                {
                  id: 1,
                  titulo: "Tijuana 2023",
                  clave: "tijuana2023",
                  icono: "siniestralidad",
                  ruta: "/sin/2023/tijuana2023",
                  win: "",
                  menus: [],
                },
                {
                  id: 1,
                  titulo: "Hermosillo 2023",
                  clave: "hermosillo2023",
                  icono: "siniestralidad",
                  ruta: "/sin/2023/hermosillo2023",
                  win: "",
                  menus: [],
                },
                {
                  id: 1,
                  titulo: "Reynosa 2023",
                  clave: "reynosa2023",
                  icono: "siniestralidad",
                  ruta: "/sin/2023/reynosa2023",
                  win: "",
                  menus: [],
                },
                {
                  id: 1,
                  titulo: "Saltillo 2023",
                  clave: "Saltillo2023",
                  icono: "siniestralidad",
                  ruta: "sin/2023/saltillo2023",
                  win: "",
                  menus: [],
                },
                {
                  id: 1,
                  titulo: "Mazatlán 2023",
                  clave: "Mazatlán2023",
                  icono: "siniestralidad",
                  ruta: "sin/2023/mazatlan2023",
                  win: "",
                  menus: [],
                },
                {
                  id: 1,
                  titulo: "Cuernavaca 2023",
                  clave: "hermosillo2023",
                  icono: "siniestralidad",
                  ruta: "sin/2023/hermosillo",
                  win: "",
                  menus: [],
                },
                {
                  id: 1,
                  titulo: "Querétaro 2023",
                  clave: "querétaro2023",
                  icono: "siniestralidad",
                  ruta: "sin/2023/queretaro",
                  win: "",
                  menus: [],
                },
                {
                  id: 1,
                  titulo: "Cuernavaca 2023",
                  clave: "cuernavaca2023",
                  icono: "siniestralidad",
                  ruta: "sin/2023/cuernavaca",
                  win: "",
                  menus: [],
                },
                {
                  id: 1,
                  titulo: "Puebla 2023",
                  clave: "puebla2023",
                  icono: "siniestralidad",
                  ruta: "sin/2023/puebla2023",
                  win: "",
                  menus: [],
                },
                {
                  id: 1,
                  titulo: "Veracruz 2023",
                  clave: "veracruz023",
                  icono: "siniestralidad",
                  ruta: "sin/2023/veracruz2023",
                  win: "",
                  menus: [],
                },
                {
                  id: 1,
                  titulo: "Coatzacoalcos 2023",
                  clave: "coatzacoalcos2023",
                  icono: "siniestralidad",
                  ruta: "sin/2023/coatzacoalcos2023",
                  win: "",
                  menus: [],
                },
                {
                  id: 1,
                  titulo: "Estado de méxico 2023",
                  clave: "estadtodeméxico",
                  icono: "siniestralidad",
                  ruta: "sin/2023/estadomexico2023",
                  win: "",
                  menus: [],
                },
                {
                  id: 1,
                  titulo: "Villahermosa",
                  clave: "Villahermosa",
                  icono: "siniestralidad",
                  ruta: "sin/2023/villahermosa2023",
                  win: "",
                  menus: [],
                },            
             ],
            },
            {
              id: 1,
              titulo: "Siniestralidad 2024",
              clave: "siniestralidad2024",
              icono: "siniestralidad",
              ruta: "",
              win: "",
              menus: [
                {
                  id: 1,
                  titulo: "Tijuana 2024",
                  clave: "tijuana2024",
                  icono: "siniestralidad",
                  ruta: "/sin/2024/tijuana2024",
                  win: "",
                  menus: [],
                },
                {
                  id: 1,
                  titulo: "Hermosillo 2024",
                  clave: "hermosillo2024",
                  icono: "siniestralidad",
                  ruta: "/sin/2024/hermosillo2024",
                  win: "",
                  menus: [],
                },
                {
                  id: 1,
                  titulo: "Reynosa 2024",
                  clave: "hermosillo2024",
                  icono: "siniestralidad",
                  ruta: "/sin/2024/hermosillo2024",
                  win: "",
                  menus: [],
                },
                {
                  id: 1,
                  titulo: "Saltillo 2024",
                  clave: "Saltillo2024",
                  icono: "siniestralidad",
                  ruta: "sin/2024/saltillo",
                  win: "",
                  menus: [],
                },
                {
                  id: 1,
                  titulo: "Mazatlán 2024",
                  clave: "Mazatlán2024",
                  icono: "siniestralidad",
                  ruta: "sin/2024/hermosillo",
                  win: "",
                  menus: [],
                },
                {
                  id: 1,
                  titulo: "Cuernavaca 2024",
                  clave: "hermosillo2024",
                  icono: "siniestralidad",
                  ruta: "sin/2024/hermosillo",
                  win: "",
                  menus: [],
                },
                {
                  id: 1,
                  titulo: "Querétaro 2023",
                  clave: "querétaro2023",
                  icono: "siniestralidad",
                  ruta: "sin/2024/queretaro",
                  win: "",
                  menus: [],
                },
                {
                  id: 1,
                  titulo: "Cuernavaca 2023",
                  clave: "cuernavaca2023",
                  icono: "siniestralidad",
                  ruta: "sin/2024/cuernavaca",
                  win: "",
                  menus: [],
                },
                {
                  id: 1,
                  titulo: "Puebla 2023",
                  clave: "puebla2023",
                  icono: "siniestralidad",
                  ruta: "sin/2024/puebla2023",
                  win: "",
                  menus: [],
                },
                {
                  id: 1,
                  titulo: "Veracruz 2023",
                  clave: "veracruz023",
                  icono: "siniestralidad",
                  ruta: "sin/2024/veracruz2023",
                  win: "",
                  menus: [],
                },
                {
                  id: 1,
                  titulo: "Coatzacoalcos 2024",
                  clave: "coatzacoalcos2024",
                  icono: "siniestralidad",
                  ruta: "sin/2024/coatzacoalcos2024",
                  win: "",
                  menus: [],
                },
                {
                  id: 1,
                  titulo: "Estado de méxico 2024",
                  clave: "estadtodeméxico",
                  icono: "siniestralidad",
                  ruta: "sin/2024/estadomexico2024",
                  win: "",
                  menus: [],
                },
                {
                  id: 1,
                  titulo: "Villahermosa",
                  clave: "Villahermosa",
                  icono: "siniestralidad",
                  ruta: "sin/2024/villahermosa2024",
                  win: "",
                  menus: [],
                },
                {
                  id: 1,
                  titulo: "Oaxaca",
                  clave: "oaxaca",
                  icono: "siniestralidad",
                  ruta: "sin/2024/oaxaca2024",
                  win: "",
                  menus: [],
                },
  
              ],
            },
          ],
        },
        {
          id: 2,
          titulo: "Cotizador",
          icono: "cotizacion",
          clave: "cotizacion",
          ruta: "/",
          win: "",
          menus: [
            {
              id: 1,
              titulo: "Cotizador",
              icono: "cotizacion",
              clave: "cotizacion",
              ruta: "/cotizador",
              win: "",
              menus: [],
            },
          ],
        },
        {
          id: 3,
          titulo: "Control de obras",
          icono: "obra",
          clave: "controlobras",
          ruta: "/",
          win: "",
          menus: [{
            id: 4,
            titulo: "Control de Obras",
            icono: "obra",
            clave: "estcontrolfacturas",
            ruta: "/",
            win: "",
            menus: [{
              id: 2,
              titulo: "Analisis de siniestralidad",
              icono: "obra",
              clave: "estanalisissiniestralidad",
              ruta: "/est/analisis",
              win: "",
              menus: [],
            },],
          },],
        },
        {
          id: 3,
          titulo: "Control de facturas",
          icono: "factura",
          clave: "controldefacturas",
          ruta: "/",
          win: "",
          menus: [{
            id: 4,
            titulo: "Control de facturas",
            icono: "beer",
            clave: "estcontrolfacturas",
            ruta: "/",
            win: "",
            menus: [{
              id: 2,
              titulo: "Analisis de siniestralidad",
              icono: "beer",
              clave: "estanalisissiniestralidad",
              ruta: "/est/analisis",
              win: "",
              menus: [],
            },],
          },],
        },
        {
          id: 4,
          titulo: "Estadísticas",
          clave: "siniestralidad",
          icono: "estadisticas",
          ruta: "/",
          win: "",
          menus: [
            {
              id: 4,
              titulo: "Siniestralidad",
              clave: "estadisticas",
          icono: "siniestralidad",
              ruta: "/",
              win: "",
              menus: [{
                id: 1,
                titulo: "Analisis de siniestralidad",
                icono: "estadisticas",
                clave: "estanalisissiniestralidad",
                ruta: "/est/analisis",
                win: "",
                menus: [],
              },
              {
                id: 2,
                titulo: "Analisis de siniestralidad",
                icono: "estadisticas",
                clave: "estanalisissiniestralidad",
                ruta: "/est/analisis",
                win: "",
                menus: [],
              },],
            },
            {
              id: 4,
              titulo: "Control de facturas",
              icono: "beer",
              clave: "estcontrolfacturas",
              ruta: "/",
              win: "",
              menus: [{
                id: 2,
                titulo: "Analisis de siniestralidad",
                icono: "beer",
                clave: "estanalisissiniestralidad",
                ruta: "/est/analisis",
                win: "",
                menus: [],
              },],
            },
            {
              id: 4,
              titulo: "Control de carretro",
              icono: "beer",
              clave: "estcontrolfacturas",
              ruta: "/",
              win: "",
              menus: [{
                id: 2,
                titulo: "Analisis de siniestralidad",
                icono: "beer",
                clave: "estanalisissiniestralidad",
                ruta: "/est/analisis",
                win: "",
                menus: [],
              },],
            },
          ],
        },
        // Configurcion
        {
          id: 4,
          titulo: "Configuración",
          icono: "config",
          clave: "configuracion",
          ruta: "/",
          win: "",
          menus: [
            {
              id: 1,
              titulo: "Usuarios",
              icono: "usuarios",
              clave: "usuarios",
              ruta: "/cnf/usuarios",
              win: "",
              menus: [],
            },
          ],
        },
        {
          id: 3,
          titulo: "Control carretero",
          icono: "carretro",
          clave: "controlcarretero",
          ruta: "https://controlcarretero.com/gnp/login.aspx?ReturnUrl=%2fgnp%2fApplication%2fdashboard.aspx",
          win: "_blank",
          menus: [],
        },
  
        {
          id: 3,
          titulo: "WahatsApp",
          icono: "whatsapp",
          clave: "whatsap",
          ruta: "",
          win: "_blank",
          menus: [],
        },
      ],
    };
    */

  const data = {
    correo: "",
    permisos: []
  }



  useEffect(() => {
    sessionStorage.setItem('data', JSON.stringify(data))
    const fetchroles = async () => {
      try {
        const proyecto = Configuracion.proyecto;
        const emailusuario = autusuario.email;
        const response = await apibig.post("/api/permisos/getpermisosxusuario", { proyecto: proyecto, emailusuario: emailusuario })
        sessionStorage.removeItem('data')

        sessionStorage.setItem('data', JSON.stringify(response.data));
      } catch (error) {
        console.error("Error al obtener usuarios:", error);
      } finally {
        // setCargando(false);
      }
    };

    fetchroles()
    const unSuscribirAuth = onAuthStateChanged(
      auth,
      async (authenticatedUser) => {
        if (authenticatedUser) {
          authenticatedUser.configuracion = Configuracion;
          setAutUsuario(authenticatedUser);
          setUsuario(authenticatedUser);
          let dataitem = JSON.parse(sessionStorage.getItem('data'));
          setPermisos(dataitem);
          setAuthState("Dashboard");
        } else {
          setUsuario(null);
          setAuthState("login");
        }
      }
    );

    return unSuscribirAuth;
  }, [usuario]);

  if (authState === null)
    return (
      <div className="font-bold text-center text-5xl py-[15vh]">
        Cargando...
      </div>
    );
  if (authState === "login")
    return <Login setAuthState={setAuthState} autusuario={autusuario} />;
  if (usuario)
    return (
      <div>
        <HashRouter>
          <Routes>
            <Route>
              <Route path="/auth/" element={<LayoutAuth />}>
                <Route index element={<LayoutAuth />} />
              </Route>
              <Route path="/" element={<LayoutSplash autusuario={autusuario} permisos={permisos} />}></Route>
              <Route path="/" element={<LayoutAd1 autusuario={autusuario} permisos={permisos} />}>
                {/* sineistralidad*/}
                <Route path="/siniestralidad" element={<Siniestralidad />} />
                {/* Estadisticas */}
                <Route path="/estadísticas" element={<Estadisticas />} />
                {/* configuracion */}
                <Route path="/cnf/usuarios" element={<Settings autusuario={autusuario} />} />
              </Route>
            </Route>
            {/* Errores */}
            <Route path="*" element={<Error404 />}></Route>
          </Routes>
        </HashRouter>
      </div>
    );
}

export default App;
