import { React} from "react";
import { useState, useEffect, useRef } from "react";
import Sidebar from "../components/Sidebar";
import Splash from "../paginas/Admin/Esc/Splash";
function LayoutSplash({ autusuario,permisos }) {
  return (
    <>    
    <div className="flex flex-row h-screen"> 
        <Sidebar autusuario={autusuario} permisos={permisos}/>
        <div className="w-full ">
          <Splash autusuario={autusuario} permisos={permisos}/>
        </div>
      </div>
    </>
  );
}
export default LayoutSplash;
