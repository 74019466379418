import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import { getStorage ,ref,uploadBytes} from "firebase/storage";
const firebaseConfig = {
  apiKey: "AIzaSyD2AqyMtf9hooARekZIwXteY-w37eXNfiA",
  authDomain: "siniestrosapp-aea1a.firebaseapp.com",
  projectId: "siniestrosapp-aea1a",
  storageBucket: "siniestrosapp-aea1a.firebasestorage.app",
  messagingSenderId: "1065951645746",
  appId: "1:1065951645746:web:f0374d131859e0ca7d6c2b"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app); // auth 

export const storage = getStorage(app);// storage 

export function upfileFirebase(file) {
 const strageRef = ref(storage, file.name);
  uploadBytes(strageRef, file).then((snapshot) => {

  })
}
const provider = new GoogleAuthProvider()
export const signInWithGoogle = () => {
  signInWithPopup(auth, provider)
    .then((result) => {      
      })
    .catch((err) => {});
};
