import { React, useEffect, useState } from "react";
import { auth } from "../data/fire";
import { Link } from "react-router-dom";
import SidebarItem from './SidebarItem'
import { MdEventNote } from "react-icons/md";
import { IoMdHome } from "react-icons/io";
import { FaChartPie } from "react-icons/fa";
import { IoSettingsOutline, IoSchool,IoClose, IoMenu } from "react-icons/io5";

import { FaWhatsapp, FaChevronDown, FaChevronLeft, FaChevronRight, FaRoute, FaDoorOpen } from "react-icons/fa";
import logosidebar from "./../auth/Login/logobarcapufe.png"

import Configuracion from "../data/configuracion";
import apibig from "../components/Apibig" // Importa la configuración de Axios
const icons = {
    menu: IoMenu,
    carretro: FaRoute,
    chevrondown: FaChevronDown,
    chevronleft: FaChevronLeft,
    chevronright: FaChevronRight,
    config: IoSettingsOutline,
    siniestralidad: MdEventNote,
    home: IoMdHome,
    whatsapp: FaWhatsapp,
    close: IoClose,
    salir: FaDoorOpen,
    capacitacion: IoSchool,
    estadisticas: FaChartPie
};
const DynamicIcon = ({ iconName, color, size }) => {
    const IconComponent = icons[iconName];
    return IconComponent ? (
        <IconComponent className={`text-[${size}] mt-1`} color={color} />
    ) : null; // Puedes personalizar tamaño y color
};







const Sidebar = ({ autusuario, permisos }) => {
    const usrimg = autusuario.photoURL;
    const usuario = autusuario.displayName;
    const email = autusuario.email;
    const [expanded, setExpanded] = useState(false);

    const [datapermisos, setDatapermisos] = useState(permisos.permisos);


    useEffect(() => {
        let dataitem = JSON.parse(sessionStorage.getItem('data'));
    
    const fetchroles = async () => {
      try {
        const proyecto = Configuracion.proyecto;
        const emailusuario = autusuario.email;
        const response = await apibig.post("/api/permisos/getpermisosxusuario", { proyecto: proyecto, emailusuario: emailusuario })
        sessionStorage.setItem('data', JSON.stringify(response.data));
        let  cnt = JSON.parse(sessionStorage.getItem('data'))
        setDatapermisos(cnt.permisos) 
        } catch (error) {
        console.error("Error al obtener usuarios:", error);
      } finally {
        // setCargando(false);
      }
    };
    fetchroles()
      }, [datapermisos]);
    
    
    




    function handleClose() {
        auth.signOut();
      }
    return (
        <div className={`${expanded ? "w-[25vw]" : "w-[5vw]"} text-white  
               bg-sidebar-100 h-screen flex flex-col`}>
            <div  className="flex flex-row justify-between">
                <div className={`${expanded ? "w-full pl-5 mb-2" : "hidden"} `}>
                    <img className={`w-[12vw] ml-10 mt-2 ${expanded ? "" : "hidden"}`} src={logosidebar} alt="" />
                </div>
                <div className={`${expanded ? "mr-4" : "px-4 pt-5 flex justify-center items-center"} `}>
                    <Link onClick={() => { setExpanded(!expanded) }}>
                        <DynamicIcon iconName="menu" size={expanded ? "1.5em":"1.5em"} />
                    </Link>
                </div>
            </div>
            <div className={`${expanded ? "" : "hidden"} flex flex-col justify-center items-center`}>
                <span ><img className="w-[5vw] rounded-[50%]"src={usrimg}/></span>
                <span className="text-[12px]">{usuario}</span>
                <span  className="text-[12px]">{email}</span>
                </div>
            <div
                className={`w-full flex flex-col gap-1 p-4 bg-sidebar-100 text-white h-screen shadow-sm`}>
                <div className={`${expanded ? 
                    "flex flex-row flex justify-between items-center h-[5vh] w-[19vw] bg-menusidebar-100 hover:bg-menusidebar-200 text-white  rounded-md gap-2":
                    "flex flex-row flex justify-between items-center  pb-1  text-white rounded-md gap-2 "} `}>
                    
                    <Link to="/" className={`${expanded ? "w-full flex justify-center items-center":""}`}>
                     <DynamicIcon iconName="home" size={expanded ? "1.5em":"1.5em"} />
                    <span className={`${expanded ? "text-[14px] w-full mt-1 px-1":"hidden"}`}>Inicio</span></Link>
                </div>
                {datapermisos.map((item, index) => <SidebarItem key={index} item={item} expanded={expanded} />)}

                <div className={`${expanded ? 
                    "flex flex-row flex justify-between items-center  h-[6vh]  w-[19vw] bg-menusidebar-100 hover:bg-menusidebar-200 text-white  rounded-md gap-2":
                    "flex flex-row flex justify-between items-center  pb-1  text-white rounded-md gap-2 "} `}>
                    
                    <Link  onClick={handleClose}
                     to="/" className={`${expanded ? "w-full pl-1 flex justify-center items-center":""}`}><DynamicIcon iconName="salir" size="1.5em"/> 
                    <span className={`${expanded ? "text-[14px] w-full mt-1 px-1":"hidden"}`}>Salir</span></Link>
                </div>
            </div>
        </div>

    )
}
export default Sidebar