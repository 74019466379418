import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MdEventNote } from "react-icons/md";
import { IoMdHome } from "react-icons/io";
import { FaChartPie } from "react-icons/fa";
import { IoSettingsOutline, IoSchool,IoClose, IoMenu } from "react-icons/io5";

import { FaWhatsapp, FaChevronDown, FaChevronLeft, FaChevronRight, FaRoute, FaDoorOpen } from "react-icons/fa";


import logotiposplash from "../../../auth/Login/logosplash.png";
import Configuracion from "../../../data/configuracion";
import apibig from "../../../components/Apibig" // Importa la configuración de Axios

const icons = {
  menu: IoMenu,
  carretro: FaRoute,
  chevrondown: FaChevronDown,
  chevronleft: FaChevronLeft,
  chevronright: FaChevronRight,
  config: IoSettingsOutline,
  siniestralidad: MdEventNote,
  home: IoMdHome,
  whatsapp: FaWhatsapp,
  close: IoClose,
  salir: FaDoorOpen,
  capacitacion: IoSchool,
  estadísticas: FaChartPie
};
const DynamicIcon = ({ iconName,color }) => {
  const IconComponent = icons[iconName];
  return IconComponent ? (
    <IconComponent className="text-[1em] mt-1" color={color} />
  ) : null; // Puedes personalizar tamaño y color
};

const Splash = ({ autusuario, permisos }) => {
  const [datapermisos, setDatapermisos] = useState(permisos.permisos);
  const [isopen, setIsopen] = useState(false);
  const [isopen2, setIsopen2] = useState(false);
  const [titulo, setTitulo] = useState("");
  const [menus, setMenus] = useState([]);
  const handleOpenmenu = (titulo, menus) => {
    setIsopen(!isopen);
    setTitulo(titulo);
    setMenus(menus);
  };

  useEffect(() => {
    let dataitem = JSON.parse(sessionStorage.getItem('data'));

const fetchroles = async () => {
  try {
    const proyecto = Configuracion.proyecto;
    const emailusuario = autusuario.email;
    const response = await apibig.post("/api/permisos/getpermisosxusuario", { proyecto: proyecto, emailusuario: emailusuario })
    sessionStorage.setItem('data', JSON.stringify(response.data));
    let  cnt = JSON.parse(sessionStorage.getItem('data'))
    setDatapermisos(cnt.permisos) 
    } catch (error) {
    console.error("Error al obtener usuarios:", error);
  } finally {
    // setCargando(false);
  }
};
fetchroles()
  }, [datapermisos]);




  /* Submenu nivel 1 */
  const Submenusplash = ({ isopen, titulo, menus }) => {
    const [isOpen, setIsOpen] = useState(isopen);

    return (
      <div
        onClick={() => {
          setIsopen(!isOpen);
          console.log(isOpen);
        }}
        className={`relative ${isOpen ? "" : "hidden"}  bg-gray-300 min-h-[70vh] w-full -top-[31vh] rounded-md p-1 gap-2`}
      >
        <div className="flexjustify-between gap-2 px-4  bg-menusplash-100  rounded-md text-white font-bold items-center">
          <Link to="/" className="flex justify-between w-full gap-1 ">
            <span className="w-full text-center">{titulo}</span>
            <DynamicIcon iconName="close" />
          </Link>
        </div>
        <div className="m-2">
          <ul className="w-full grid grid-cols-3 gap-2">
            {menus.map((menu) => (
              <li className="px-4  hover:bg-menusplash-100 bg-menusplash-200 text-white h-7 rounded-[30vh] ">
                <Link to={menu.ruta} className="flex justify-between w-full gap-1 ">
                  <DynamicIcon iconName={menu.icono} />
                  <span className="w-full">{menu.titulo} </span> 
                   {menu.menus.length===0?<></>:
                      <DynamicIcon iconName="chevrondown" />}
                </Link>
                {menu.menus.length !== 0 ? (
                  <ul className="mt-2">
                    {menu.menus.map((menu1) => (
                      <li className="text-[.8rem] mt-1  hover:text-menusplash-200 text-menusplash-100 hover:text-bold h-4 rounded-[30vh] ">
                        <Link to={menu1.ruta} className="flex justify-left w-full gap-1 "><DynamicIcon iconName={menu.icono}/>
                        {menu1.titulo} </Link></li>
                    ))}
                  </ul>
                ) : (
                  <></>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="flex flex-row h-screen bg-gray-100 justify-center">
        <div className="w-[90%] py-10 flex flex-col justify-star items-center bg-white my-[5vh] gap-2 p-[2vh] rounded-[2vw] shadow-gray-400 shadow ">
          <div className="w-[20%]">
            <img src={logotiposplash} />
          </div>
          <div className="text-titulo1splash-100 text-[1.8rem] font-bold items-center">
            Bienvendo a siniestrosApp
          </div>
          <div className="text-titulo2splash-100 text-[.8rem] text-justify font-bold px-[7vw]">
            Aquí encontrarás la administración para la operación de la cuenta
            FIDEGAR como informes, estadísticas de la cuenta, operación y
            capacitación, con la finalidad de manter un alto estandar de calidad
            y servicio profesional.
          </div>
          <div className="w-full h-screen">
            <ul className="w-full grid grid-cols-4 gap-2">
              <li className="col-1 px-4 hover:bg-menusplash-100 bg-menusplash-200 text-white h-7 rounded-[30vh] ">
                <Link to="/" className="flex w-full gap-1 ">
                  <DynamicIcon iconName="home" />
                  Inicio
                </Link>
              </li>
              {datapermisos.map((menu) => (
                <li
                  key={menu.clave}
                  className="col-1 px-4 hover:bg-menusplash-100 bg-menusplash-200 text-white h-7 rounded-[30vh] "
                >
                  <Link
                    onClick={() => {
                      if (menu.menus.length !== 0) {
                        handleOpenmenu(menu.titulo, menu.menus);
                      }
                    }}
                    to={menu.ruta} 
                    target={menu.win}
                    className="flex justify-left w-full gap-1 "
                  >
                    <DynamicIcon iconName={menu.icono} />
                   <span className="w-full">{menu.titulo} </span> 
                   {menu.menus.length===0?<></>:
                      <DynamicIcon iconName="chevrondown" />}
                  </Link>
                  
                </li>
                
              ))}
            </ul>
            <Submenusplash isopen={isopen} titulo={titulo} menus={menus} />
          </div>
        </div>
      </div>
    </>
  );
};
export default Splash;
